<template>
    <div class="list-box">
        <i-header :title="config['title'] ? config['title'].template : ''"
                  :showBack="true" @back-event="toBack">
          <div v-if="config['title'] && config['title'].rightActions" slot="right" @click="handleRightActions(config['title'].rightActions)" class="right_action">{{config['title'].rightActions.text}}</div>
        </i-header>
        <i-pull-refresh class="cnt-box" v-model="refreshing" @refresh="onRefresh">
            <i-list id="scrollCnt"
                    v-model="loading" :immediate-check="false"
                    :finished="finished" :finished-text="finishedTxt"
                    @load="queryList">
                <component key="static" v-if="config['headline'] && config['headline'].length != 0"
                           :is="$evalTemplate('Vnode', config['headline'].template)"></component>
                <template v-if="docList.length">
                    <template v-for="item in docList">
                        <div :key="item.$_id" :id="item.$_id">
                            <ListCard :info="item" :btn-list="item.btns || []" :showBtn="true"
                                      :tips="item.tips" :template="item.template"
                                      :fileList="item.fileList" :updateFileBox="showActiveForm"
                                      @toOptions="beforeOptions" :key="item.$_id"></ListCard>
                        </div>
                    </template>
                </template>
                <i-skeleton v-if="isFirst" title :row="8"/>
                <empty-box v-if="!docList.length && !isFirst && !loading"/>
            </i-list>
        </i-pull-refresh>
        <i-add-button ref="iAddButton" v-if="!$route.query.readOnly" :optionsList="plusOptions" style="z-index: 3" @click="addDoc" />

        <iPopup :style="{ padding: padding, width: '100vw', height: '100vh' }"
                get-container="#genericListBox"
                ref="pop" v-model="showActiveForm" position="right">
            <activeForm v-if="showActiveForm" :formConfig="activeFormConfig"
                        @close="hideForm" @send="addDocList"></activeForm>
        </iPopup>
        <ItemPop ref="subtypePop" get-container="#genericListBox"
                 @handlechannel="handlePopItem"
                 :itemList="subtypePopList" :title="subtypePopTitle"></ItemPop>
        <div v-if="config.listBtns && config.listBtns.length" class="confirm-box">
            <i-button v-for="(item, index) in config.listBtns" :key="index" round color="var(--themeColor)" block @click="toSubmit(item)" :loading="btnLoading">确定</i-button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import emptyBox from "@/components/iEmpty";
import ListCard from "./ListCard";
import generic from "../../utils/generic";
import activeForm from "../activeForm.vue";
import ItemPop from "@/components/ItemPop"

export default {
    name: "List",
    components: {
        emptyBox,
        ListCard,
        activeForm,
        ItemPop
    },
    data() {
        return {
            // mainListKey: { Attendee: "attendeeId", Speaker: "speakerId", ExpenseAllocation: "allocationId" }[this.$route.query.type],
            miceInfo: this.$smartStorage.get("miceInfo"),
            activeFormConfig: null,
            showActiveForm: false,
            onlyRead: false,
            showMoreTips: false,
            pageIndex: 1,
            pageSize: 10,
            docList: [],
            total: 0,
            isFirst: true,
            loading: false,
            finished: false,
            finishedTxt: "",
            tipsCode: {
                Attendee: "ievent-add-attendee",
                Speaker: "ievent-add-speaker",
            },
            count: 0,
            config: {
                list: [],
                plusOptions: [],
                operationBtns: [],
            },
            plusOptions: [],

            listScrollHeight: 0,  // 列表滚动距离
            fileList: [],
            subtypePopList: [],
            subtypePopTitle: '',
            refreshing: false,
            currentCardInfo: null  // 当前点的card信息
        };
    },
    created() {
        this.getConfig();
    },
    mounted() {
        // 全屏点击事件
        document.addEventListener('touchmove', this.handleTouchmove, { passive: false })
        document.addEventListener("click",this.clickEvent)
        document.querySelector("#scrollCnt") && document.querySelector("#scrollCnt").addEventListener("scroll",this.scrollToTop)
    },
    beforeDestroy() {
        document.removeEventListener('touchmove',this.handleTouchmove,{ passive: false })
        document.removeEventListener("click",this.clickEvent)
        // document.querySelector("#scrollCnt").removeEventListener("scroll",this.scrollToTop)
    },
    methods: {
        handleTouchmove(e) {
            setTimeout(() => {
                if (!document.querySelector('.list-box')?.contains(e.target)) {
                    e.preventDefault();
                }
            }, 500)
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.queryList();
        },
        // 点击右侧
        handleRightActions({route, group, readOnly}){
          this.$router.push({
              name: route ? route : "funcList",
              query: {type: group, readOnly: readOnly, miceId: this.miceInfo.MiceId
              }
            });
        },
        // 点击自身意外的区域关闭弹窗
        clickEvent(e) {
            let iAddButton = document.querySelector(".i-add-button")
            if(iAddButton && !iAddButton.contains(e.target)) {
                this.$refs['iAddButton'].showBtnOp = false
            }
        },
        // 滚动距离
        scrollToTop() {
            this.listScrollHeight = document.querySelector("#scrollCnt")?.scrollTop || 0
        },
        getConfig() {
            generic
                .getConfigs(
                    {
                        miceId: this.$route.query.miceId,
                        group: this.$route.query.type,
                        router: this.$route.path,
                    },
                    this.$smartStorage.get("miceInfo")
                )
                .then((config) => {
                    this.config = config;
                    for (const configKey in config) {
                        if (config[configKey].callback) this[config[configKey].callback]();
                    }
                });
        },
        setPlusOptions() {
            this.plusOptions = this.config["plusOptions"].options || [];
        },
        hideForm() {
            this.showActiveForm = false;
        },
        toSubmit(e) {
            let btnConfig = generic.replaceEvalObject(this.jsonCopy(e), this.docList);
            this.$service.GenericPost(btnConfig).then((res) => {
                this.btnLoading = false;
                if (res && res.success) {
                    this.$itoast("操作成功");
                    this.$router.back();
                }
            });
        },
        addDocList(e,isEdit) {
            let btnConfig = generic.replaceEvalObject(isEdit ? this.config.update : this.config.create, [e]);
            this.$service.GenericPost(btnConfig).then((res) => {
                this.btnLoading = false;
                if (res && res.success) {
                    this.queryList();
                    this.$itoast("操作成功");
                    this.hideForm()
                }
            });
        },
        // 拿列表数据
        queryList() {
            // let _config = generic.replaceEval(this.config["list"], this.$route.query)[0];
            let _config = generic.replaceEvalObject(this.config["list"], this.$route.query);
            this.loading = true;
            const results = _config.requestMethod === 'get' ? this.$service.Get(_config.api, {params: _config.params}) : this.$service.common(_config.api, _config.params);
            results.then((res) => {
                this.loading = false;
                this.finished = true;
                this.isFirst = false;
                if (this.refreshing) {
                    this.docList = [];
                    this.refreshing = false;
                }
                if (res && res.success) {
                  // 列表按钮
                  // item---tips
                    let resource;
                    if (_config.dataContentPath) {
                        try {
                            resource = eval("res." + _config.dataContentPath);
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        resource = res.content || [];
                    }
                    resource.forEach((item) => {
                    let btnConfig = this.config["operationBtns"]?.options || [];
                    item.btns = generic.replaceEval(btnConfig, this.jsonCopy(item));
                    // 列表主键
                    if(this.config["list"].mainListKey) item.$_id = 'id_' + item[this.config["list"].mainListKey]
                    if (item.fee === null) item.tips = "待完善信息";
                    if (this.config["listCard"]) {
                      let tmp = JSON.parse(JSON.stringify(this.config["listCard"].template));
                      if (tmp.tag) {
                        let context = item;
                        tmp.tag.forEach((tag) => {
                          try {
                            tag.show = eval(tag.show);
                          } catch (error) {
                            console.log(error);
                          }
                          return tag;
                        });
                      }
                      item.template = tmp;
                      item.fileList = tmp.uploadOptions ? this.handleFileConfig(tmp.uploadOptions,item) : []
                      // 备份
                      item.uploadOptions = tmp.uploadOptions || null
                    }
                    return item;
                  });
                  this.docList = [];
                  this.docList = resource;
                  console.log("this.docList==",this.docList)
                  if (this.docList.length > 0) this.finishedTxt = "没有更多了";
                  this.count = resource.length;
                  this.$nextTick(() => {
                    // 锚定到指定位置
                    if(this.$smartStorage.get('genericDetailInfo')) {
                      let anchorId = this.$smartStorage.get('genericDetailInfo').$_id
                      if(document.getElementById(anchorId)) {
                        document.getElementById(anchorId).scrollIntoView()
                      }
                    }
                  },500);
                }
              });
        },
        // 处理文件模块
        handleFileConfig(fileConfig,item) {
            let fileTmp = []
            item.showActiveForm = this.showActiveForm
            fileConfig.map((fileItem) => {
                fileItem.filterData = {
                    catalog: fileItem.catalog,
                    relatedId: item.explanationId || '',
                    processId: this.$route.query.miceId,
                };
                if (fileItem.replaceEvalPath) {
                    let cacheItem = generic.replaceEvalObject(this.jsonCopy(fileItem),
                            Object.assign(this.jsonCopy(item),this.$smartStorage.get('miceInfo')));
                    let visibleRuleExpr = cacheItem.visibleRuleExpr;
                    if (visibleRuleExpr !== false) fileTmp.push(cacheItem);
                } else {
                    fileTmp.push(fileItem);
                }
            })
            return fileTmp
        },
        // 处理半弹窗的子类型
        handlePopItem(type, popItem) {
            if(popItem.callBack) {
                let popItemConfig = generic.replaceEvalObject(this.jsonCopy(popItem),this.jsonCopy(this.currentCardInfo))
                this[popItem.callBack](popItemConfig,this.currentCardInfo)
                return false
            }
        },
        beforeOptions(act, item) {
            this.currentCardInfo = this.jsonCopy(item)
            // 按钮前置操作
            let isCheckAllPass = true
            if(act.conditionExpr && act.conditionExpr.length > 0) {
                let context = Object.assign(this.$smartStorage.get('miceInfo'), { actItem: item })
                for (var i = 0; i < act.conditionExpr.length; i++) {
                    if(generic.replaceVal(act.conditionExpr[i]?.expr,context)) {
                        isCheckAllPass = false
                        this.$itoast(act.conditionExpr[i]?.errorMsg)
                        break
                    }
                }
            }
            if(!isCheckAllPass) return false;
            if (act.formConfig) {
                this.showActiveForm = true;
                act.formConfig.formData = this.jsonCopy(item);
                act.formConfig.fileList = item.uploadOptions ? this.handleFileConfig(item.uploadOptions,item) : [];
                this.activeFormConfig = generic.replaceEvalObject(act.formConfig, Object.assign(this.jsonCopy(item), this.miceInfo));
                return false;
            }
            if (act.router) {
                this.$smartStorage.set('genericDetailInfo',item)
                let _query = Object.assign(this.$route.query, { subType: item?.val })
                if(act.query) _query = Object.assign(_query,act.query)
                this.$router.push({
                    path: act.router,
                    query: _query,
                });
                return false;
            }
            if(act.popList) {
                this.subtypePopList = act.popList
                this.subtypePopTitle = act.popTitle
                this.$refs.subtypePop.open()
                return false
            }
            // TODO 此处考虑合并
            if (act.actName == "删除" || act.doubleConfirm) {
                this.$idialog
                    .confirm({
                        title: "提示",
                        message: act.doubleConfirmMsg || "是否确认删除？",
                    })
                    .then(() => {
                        this.doOptions(act, act.callBack);
                    })
                    .catch(() => {});
            } else {
                if(!act.api) {
                    this[act.callBack](item)
                    return false
                }
                this.doOptions(act, act.callBack);
            }
        },
        doOptions(_config, callback) {
            this.$service.common(_config.api, _config.params).then((res) => {
                if (res && res.success) {
                    this.$itoast.success("操作成功！");
                    this.queryList();
                    // this[callback]();
                    if(_config.afterOptionsExpr) {
                        let context = this
                        try {
                            eval(_config.afterOptionsExpr)
                        } catch (error) {
                            console.log('afterOptionsExpr_error',error)
                        }
                    }
                    console.log(callback);
                }
            });
        },
        copyUrl(item) {
            let oInput = document.createElement("input");
            oInput.value = item.actUrl || item.extData?.argumentUrl;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;

            document.execCommand("Copy"); // 执行浏览器复制命令
            oInput.remove();

            this.$itoast.success("复制成功!");
            if(item.afterOptionsExpr) {
                let context = this
                try {
                    eval(item.afterOptionsExpr)
                } catch (error) {
                    console.log('afterOptionsExpr_error',error)
                }
            }
        },
        toBack() {
            this.$smartStorage.remove('genericDetailInfo')
            this.$router.back();
            setTimeout(() => {
              location.reload();
            },400)
        },
        async addDoc(item = null) {
            if (this.config.plusOptions.actionConfig) {
                switch (this.config.plusOptions.actionConfig.type) {
                    case "directJump":
                        this.$router.push({
                            path: this.config.plusOptions.actionConfig.router,
                            query: Object.assign(this.$route.query, this.config.plusOptions.actionConfig.query || {}),
                        });
                        break
                    case "addItemForm":
                        // 打开一个弹出框渲染表单，表单提交推入到当前的列表中
                        this.activeFormConfig = generic.replaceEvalObject(this.jsonCopy(this.config.plusOptions.actionConfig), this.miceInfo);
                        this.showActiveForm = true;
                        break;
                }
            } else {
                this.$router.push({
                    path: item.router ? item.router : "/genericSelect",
                    query: Object.assign(this.$route.query, { subType: item?.val }),
                });
            }
        },
        toEdit() {
            // 当前组件中表单添加和修改不跳转路由{
            this.$router.push({
                name: "genericSelect",
                query: Object.assign(this.$route.query, { isEdit: 1 }),
            });
        },
    },
  watch: {
      "$route"(to, from){
          console.log(to);
      }
  }
};
</script>

<style lang="less" scoped>
.list-box {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    .right_action{
      color: var(--themeColor);
    }
    /deep/.sticky-box {
        height: 0.44rem;
        box-sizing: border-box;
        .i-header .header-bg {
            background: #FFFFFF !important;
        }
    }
    .cnt-box {
        //height: calc(100vh - 1.05rem);
        flex: 1;
        padding: 0.08rem 0.1rem 0 0.1rem;
        box-sizing: border-box;
        overflow-y: auto;
        .title-box {
            text-align: left;
            color: #13161b;
            font-size: 0.24rem;
            padding: 0.1rem 0;
            box-sizing: border-box;
        }

        .count {
            font-size: 0.15rem;
            color: #909398;
            margin-top: 0.05rem;
        }
        .item-box {
            margin-top: 0.1rem;
        }
    }
    .confirm-box {
        //position: fixed;
        //bottom: 0;
        width: 100vw;
        height: 0.89rem;
        background: #ffffff;
        box-shadow: 0px -0.16rem 0.16rem 0px #ffffff;
        border-radius: 0.33rem 0.33rem 0px 0px;
        padding: 0.05rem 0.25rem 0 0.25rem;
        box-sizing: border-box;
    }
}
</style>
